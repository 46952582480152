import React, { useState } from 'react'

import {EuiButton} from "@elastic/eui"

import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel"

import Metro01 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_P2P_01.webp'
import Metro02 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_P2P_02.webp'
import Metro03 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_P2P_03.webp'
import Metro04 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_P2P_04.webp'
import Metro05 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_P2P_05.webp'
import Metro06 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_P2P_06.webp'
import Metro07 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_P2P_07.webp'
import Metro08 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_P2P_08.webp'
import Metro09 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_P2P_09.webp'

const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen }) => {
  return (
    <div>
      <AutoRotatingCarousel
        label="Return"
        open={handleOpen.open}
        onClose={() => setHandleOpen({ open: false })}
        onStart={() => setHandleOpen({ open: false })}
        mobile
        autoplay={true}
        style={{ position: "absolute" }}
      >
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro01} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera via its P2P address"
          subtitle="Enter the Multiview Menu and click the + button in the top right corner to add your camera."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro02} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera via its P2P address"
          subtitle="Choose Add a New P2P Camera to add your camera via it&apos;s UID."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro03} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera via its P2P address"
          subtitle="It is recommended to use the QRCode Reader to scan the code inside your camera&apos;s webUI. Alternatively, search for your camera on the network or type in the UID manually..."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro04} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera via its P2P address"
          subtitle="If you used the Search Function select your camera from the list of found P2P devices on your local network."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro05} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera via its P2P address"
          subtitle="Verify your UID and click next to proceed."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro06} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera via its P2P address"
          subtitle="Select a name for your camera. Each camera you add has to have a unique name."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro07} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera via its P2P address"
          subtitle="Type in the admin username for your camera and click next to proceed."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro08} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera via its P2P address"
          subtitle="Type in the admin password for your camera and click next to proceed."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro09} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Add your camera via its P2P address"
          subtitle="Verify that all details are correct and click test to let the app test the connection to your camera."
        />
      </AutoRotatingCarousel>
    </div>
  );
}

function P2PGallery() {
  const [handleOpen, setHandleOpen] = useState({ open: false });
  const handleClick = () => {
    setHandleOpen({ open: true });
  };
  return (
    <>
      <EuiButton fill onClick={handleClick}>
        Image Gallery
      </EuiButton>
      <AutoRotatingCarouselModal
        handleOpen={handleOpen}
        setHandleOpen={setHandleOpen}
      />
    </>
  );
}


export default P2PGallery